<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="660px"
  >
    <gl-wrap right="20px">
      <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
        <el-form-item prop="noticeTitle" label="公告标题">
          <div> {{formData.noticeTitle}} </div>
        </el-form-item>
        <el-form-item prop="sort" label="权重">
          <div> {{formData.sort}} </div>
        </el-form-item>
        <el-form-item prop="status" label="状态">
          <div> {{formData.status == 1 ? '开' : '关'}} </div>
        </el-form-item>
        <el-form-item prop="noticeContent" label="公告内容">
          <!-- <div class="noticeContent" v-html="formData.noticeContent"></div> -->
          <gl-editor v-model="formData.noticeContent" disabled></gl-editor>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {noticeById} from '@/api/backendall/systemSetup'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getById()
      },
    }
  },
  methods: {
    async getById(){
      this.loading = true
      const res = await noticeById(this.itemId)
      this.formData = {
        noticeId: res.data.noticeId,
        noticeTitle: res.data.noticeTitle,
        sort: res.data.sort,
        status: String(res.data.status),
        noticeContent: res.data.noticeContent
      }
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.announcementContent p{
  margin: 0;
}
</style>
<template>
  <el-dialog 
    :title="itemId ? '修改':'新增'"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="700px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
        <el-form-item prop="noticeTitle" label="公告标题" verify>
          <el-input v-model="formData.noticeTitle" placeholder="请输入公告标题"></el-input>
        </el-form-item>
        <el-form-item prop="sort" label="权重" verify>
          <el-input v-model.number="formData.sort" placeholder="请输入权重"></el-input>
        </el-form-item>
        <el-form-item v-if="itemId" prop="status" label="状态" verify>
          <gl-option v-model="formData.status" :list="statusList"></gl-option>
        </el-form-item>
        <el-form-item prop="noticeContent" label="公告内容" verify>
          <!-- <el-input type="textarea" :rows="10" v-model="formData.announcementContent" placeholder="请输入公告内容"></el-input> -->
          <gl-editor v-model="formData.noticeContent"></gl-editor>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {addNotice, updateNotice, noticeById} from '@/api/backendall/systemSetup'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
      statusList: [
        {name: '开', value: '1'},
        {name: '关', value: '0'},
      ],
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getById()
      }
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        try{
          let res = null
          if(this.itemId){
            res = await updateNotice(this.formData)
          }else{
            res = await addNotice(this.formData)
          }
          this.loading = false
          this.$message.success('操作成功')
          this.$emit('reload')
          this.handleClose()
        }catch(e){
          this.loading = false
        }
        
      })
    },
    async getById(){
      this.loading = true
      const res = await noticeById(this.itemId)
      this.formData = {
        noticeId: res.data.noticeId,
        noticeTitle: res.data.noticeTitle,
        sort: res.data.sort,
        status: String(res.data.status),
        noticeContent: res.data.noticeContent
      }
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style>

</style>
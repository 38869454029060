<template>
  <div>
    <gl-title :title="$route.meta.title"></gl-title>
    <div style="margin: 18px;">
      <el-button v-check="'107031'" type="primary" size="mini" @click="mClickAction('mUpdateVisible')"> 新增 </el-button>
    </div>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="noticeTitle" label="公告标题" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="{row}">
            <span v-if="row.status == 1" style="color: #67c23a">开</span>
            <span v-else>关</span>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="权重"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="noticeContent" label="公告内容" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button v-check="'107032'" type="text" @click="mClickAction('mUpdateVisible', scope.row.noticeId)"> 编辑 </el-button>
            <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.noticeId)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <update :visible.sync="mUpdateVisible" :itemId="mItemId" @reload="mReload"/>
    <preview :visible.sync="mPreviewVisible" :itemId="mItemId"/>
  </div>
</template>

<script>
import {noticeList} from '@/api/backendall/systemSetup'
import ListMixin from '@/mixins/list.mixin.js'
import update from './update.vue'
import preview from './preview.vue'
export default {
  components: {update,preview},
  mixins: [ListMixin],
  mounted(){
    this.mGetListFun = noticeList
    this.mGetList()
  },
}
</script>

<style>

</style>